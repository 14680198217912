const keys = {
  DISPLAY_FULL_CARD: process.env.REACT_APP_PRODUCT_FULL_CARD,
  DEFAULT_API_PATH: process.env.REACT_APP_DEFAULT_API_PATH,
  REGISTRATION_TOKEN: process.env.REACT_APP_REGISTRATION_TOKEN,
  ACCESS_TOKEN: process.env.REACT_APP_ACCESS_TOKEN,
  DEFAULT_API_DOMAIN: process.env.REACT_APP_DEFAULT_API_DOMAIN,
  DEFAULT_LANG: process.env.REACT_APP_DEFAULT_LANG,
  ORDER_LANGUAGE: process.env.REACT_APP_ORDER_LANGUAGE,

  GOOGLE_KEY: process.env.REACT_APP_GOOGLE_KEY,
  MEMBER_EXIST: process.env.REACT_APP_MEMBER_EXIST,

  AD_BUDGET_MIN: +process.env.REACT_APP_AD_BUDGET_MIN || 1.5,
  AD_BUDGET_MAX: +process.env.REACT_APP_AD_BUDGET_MAX || 1000,
  INDEXEDDB_NAME: process.env.REACT_APP_INDEXEDDB_NAME,

  regCoupon: process.env.REACT_APP_SUBSCRIPTION_REG_COUPON,
  regFreeMonth: process.env.REACT_APP_SUBSCRIPTION_REG_FREE_MONTH,
  regDiscount: process.env.REACT_APP_SUBSCRIPTION_REG_DISCOUNT,
  TRIAL_SUBSCRIPTION:
    process.env.REACT_APP_SWITCH_APP_TRIAL_SUBSCRIPTION,
  regDuration: process.env.REACT_APP_SUBSCRIPTION_REG_DURATION,

  DISCOUNT_FIVE: process.env.REACT_APP_DISCOUNT_FIVE,
  DISCOUNT_TEN: process.env.REACT_APP_DISCOUNT_TEN,
  DISCOUNT_FIFTEEN: process.env.REACT_APP_DISCOUNT_FIFTEEN,
  DISCOUNT_TWENTY: process.env.REACT_APP_DISCOUNT_TWENTY,
  PAGE_REDIRECT: process.env.REACT_APP_PAGE_REDIRECT,
  DOMAIN_URL: process.env.REACT_APP_DOMAIN_URL,

  CL_PRESET: process.env.REACT_APP_CL_PRESET,
  CL_ROOT: process.env.REACT_APP_CL_ROOT,
  CL_NAME: process.env.REACT_APP_CL_NAME,
  CL_DOMAIN: process.env.REACT_APP_CL_DOMAIN,
  IMAGES_SIZE: process.env.REACT_APP_IMAGES_SIZE,
  UPLOAD_IMAGES_LENGTH: process.env.REACT_APP_UPLOAD_IMAGES_LENGTH,

  TOKEN_TYPE: process.env.REACT_APP_TOKEN_TYPE,
  CREATE_STORE: process.env.REACT_APP_CREATE_STORE,
  STORE_DETAILS: process.env.REACT_APP_STORE_DETAILS,
  HASH_CREATE: process.env.REACT_APP_HASH_CREATE,
  HASH_DETAILS: process.env.REACT_APP_HASH_DETAILS,
  HASH_PAYMENT: process.env.REACT_APP_HASH_PAYMENT,
  PAYMENT_STEP_TOKEN: process.env.REACT_APP_APP_PAYMENT_STEP_TOKEN,
  COMPLETE_PROFILE_STEP_TOKEN:
    process.env.REACT_APP_APP_COMPLETE_PROFILE_STEP_TOKEN,

  BASIC_PLAN_ONE_MONTH: process.env.REACT_APP_BASIC_ONE_MONTH,
  BASIC_PLAN_SIX_MONTH: process.env.REACT_APP_BASIC_SIX_MONTH,
  BASIC_PLAN_ONE_YEAR: process.env.REACT_APP_BASIC_ONE_YEAR,

  PARTNER_ONE_MONTH: process.env.REACT_APP_PARTNER_ONE_YEAR,
  PARTNER_SIX_MONTH: process.env.REACT_APP_BASIC_SIX_MONTH,
  PARTNER_ONE_YEAR: process.env.REACT_APP_BASIC_ONE_YEAR,

  NUMBER_ADS_PRODUCT: process.env.REACT_APP_NUMBER_ADS_PRODUCT || 5,
  AD_MIN_ONE_DAY: process.env.REACT_APP_AD_MIN_ONE_DAY,
  AD_MIN_THREE_DAYS: process.env.REACT_APP_AD_MIN_THREE_DAYS,
  AD_MIN_ONE_WEEK: process.env.REACT_APP_AD_MIN_ONE_WEEK,
  AD_MIN_TWO_WEEKS: process.env.REACT_APP_AD_MIN_TWO_WEEKS,
  AD_MIN_ONE_MONTH: process.env.REACT_APP_AD_MIN_ONE_MONTH,

  PRODUCT_DESCRIPTION_EN: process.env.PRODUCT_DESCRIPTION_EN,
  PRODUCT_DESCRIPTION_FR: process.env.PRODUCT_DESCRIPTION_FR,
  ADVERT_DESCRIPTION_EN: process.env.ADVERT_DESCRIPTION_EN,
  ADVERT_DESCRIPTION_FR: process.env.ADVERT_DESCRIPTION_FR,
};

export default keys;
